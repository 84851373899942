html {
	body {
		div#app {
			.align-right {
				text-align:right;
			}
			nav.navbar-default {
				background-color: $colour_dark_purple;
				ul.navbar-nav {
					li {
						a {
							color: white;
							font-family: $font_pt_sans;
							text-transform: uppercase;
							font-size: 14px;
							
							&:hover {
								color: white;
								text-underline: none;
								background-color: $colour_light_purple;
							}
						}
						&.dropdown.open {
							a {
								background-color: $colour_light_purple;
							}
							ul {
								li {
									a {
										background-color: $colour_dark_purple;
										&:hover {
											background-color: $colour_light_purple;
										}
									}
								}
							}
						}
						ul {
							background-color:$colour_dark_purple;
							border-radius:0px;
							border: none;
							li.divider {
								height:0px;
							}
						}
						&.active {
							a {
								background-color:$colour_light_purple;
							}
						}
					}
					&.navbar-right {
						a {
							text-transform: none;
							color: $colour_lighter_purple;
							&:hover {
								color: $colour_lighter_purple;
							}
						}
					}
				}
				&.navbar {
					margin-bottom:0px;
				}
			}
			div.page-heading {
				margin-top:70px;
				margin-bottom:50px;
				h1 {
					font-family: $font_open_sans;
					font-size:38px;
					font-weight:100;
					margin:0;
					padding:0;
					text-transform: uppercase;
					color: $colour_dark_purple;
				}
			}
		}
	}
}