html {
	body {
		div#app {
			&.dashboard {
				h1 {
					padding: 0 0 15px 0;
					margin: 0;
					font-size:36px;
					color: $colour_dark_purple;
					font-weight:100;
					text-transform:uppercase;
				}
				
				h2 {
					padding:0;
					margin:0;
					font-size:18px;
					font-weight:100;
				}
				
				.h-auto {
					height: auto !important;
				}
				
				.newsletter {
					 background-color: #752f8a;
					 padding: 30px 0;
					 margin: 50px 0 0;
					 position: relative;
					 overflow: hidden;
				 }
				
				.newsletter .icon {
					z-index: 1;
					position: absolute;
					top: -20px;
					left: -30px;
					font-size: 140px;
					color: #ffffff;
					opacity: 0.15;
					-webkit-transform: rotate(20deg);
					-moz-transform: rotate(20deg);
					-ms-transform: rotate(20deg);
					-o-transform: rotate(20deg);
					transform: rotate(20deg);
				}
				
				.newsletter h4 {
					text-transform: uppercase;
					color: #ffffff;
					margin: 0 0 20px;
					font-family: 'Open Sans', sans-serif;
					font-weight: 700;
				}
				
				.newsletter .h4, .newsletter .btn {
					position: relative;
					z-index: 2;
				}
				div.newsletter-row {
					padding: 10px 0 50px 0;
				}
				div.dashboard-panel {
					height:auto;
					background-color: $colour_light_grey;
					padding-top: 70px;
					font-family: $font_open_sans;
					div.right {
						h1 {
							color: $colour_light_green;
						}
					}
					div.search-group {
						margin: 20px 0;
						input[type='text'] {
							width:75%;
							display:inline-block;
							height: 40px;
							border-top-right-radius: 0px;
							border-bottom-right-radius: 0px;
						}
						button.btn-green {
							width:25%;
							display:inline-block;
							border-radius:0px;
							border-top-right-radius: 4px;
							border-bottom-right-radius: 4px;
							float:right;
							padding: 8px 10px;
						}
					}
					
					div.favourite-files {
						clear:both;
						display:block;
						position: relative;
						bottom: -30px;
						margin-bottom:30px;
						visibility: hidden;
						
						div.slider {
							overflow: hidden;
						}
						
						a.file-item {
							max-width:120px;
							position:relative;
							margin-right: 26px;
							float:left;
							
							&:last-child {
								margin-right:0px;
							}
							
							span.title {
								width:100%;
								display:block;
								text-align:center;
								color: $colour_dark_purple;
								padding: 20px 0 0 0;
								font-weight: 600;
								font-family:$font_pt_sans;
								line-height:18px;
								font-size:14px;
								word-wrap: break-word;
								-webkit-transition: background-color 250ms linear, color 250ms linear;
								-moz-transition: background-color 250ms linear, color 250ms linear;
								-ms-transition: background-color 250ms linear, color 250ms linear;
								-o-transition: background-color 250ms linear, color 250ms linear;
								transition: background-color 250ms linear, color 250ms linear;
								
							}
							
							&:hover, &:active, &:visited, &:focus {
								span.title {
									text-decoration:none;
								}
								text-decoration:none;
							}
							
							div.file-preview {
								position:relative;
								
								img.file-type {
									position:absolute;
									bottom: -8px;
									right: 6px;
								}
							}
							
						}
						
						#next-arrow {
							position: absolute;
							top: 50%;
							right: -45px;
							-webkit-transform: translateY(-50%);
							-moz-transform: translateY(-50%);
							-ms-transform: translateY(-50%);
							-o-transform: translateY(-50%);
							transform: translateY(-50%);
							font-size: 36px;
							color: $colour_dark_purple;
						}
						
					}
				}
				div.panel-homes {
					padding-bottom: 50px;
					background-color: rgba($colour_dark_grey, 0.18);
					.btn {
						&:last-child {
							margin-left: 25px;
						}
						&:first-child {
							margin-right: 25px;
						}
					}
				}
				div.quick-folders {
					margin: 0;
					padding: 70px 0 50px 0;
					background-color: $colour_light_grey;
					
					h2 {
						margin-bottom:50px;
					}
					
					a.folder {
						width: 16.66%;
						height: 150px;
						float:left;
						margin-bottom:20px;
						text-decoration:none;
						img {
							width: 120px;
							height: auto;
							-webkit-transition: opacity 250ms linear;
							-moz-transition: opacity 250ms linear;
							-ms-transition: opacity 250ms linear;
							-o-transition: opacity 250ms linear;
							transition: opacity 250ms linear;
							display:block;
							margin: 0 auto;
						}
						span.name {
							width:100%;
							display:block;
							text-align:center;
							color: $colour_dark_purple;
							padding: 2px 5px;
							font-weight: 800;
							font-family:$font_pt_sans;
							line-height:16px;
							font-size:16px;
							-webkit-transition: background-color 250ms linear, color 250ms linear;
							-moz-transition: background-color 250ms linear, color 250ms linear;
							-ms-transition: background-color 250ms linear, color 250ms linear;
							-o-transition: background-color 250ms linear, color 250ms linear;
							transition: background-color 250ms linear, color 250ms linear;
							
						}
						&:hover {
							span.name {
								//background-color: white;
								color: $colour_light_purple;
								
							}
							img {
								opacity:0.8;
							}
						}
					}
				}
				div.latest-news {
					margin-bottom:100px;
					
					div.latest-news-header {
						margin-top:100px;
						
						h1 {
							margin: 0 0 100px 0;
							font-family: $font_open_sans;
							font-weight:100;
							font-size:36px;
						}
					}
					a {
						text-decoration:none;
						&:hover {
							span {
								color: $colour_light_purple;
							}
						}
					}
					span {
						font-family: $font_open_sans;
						display:block;
						&.date {
							color: $colour_dark_grey;
							font-size:12px;
							margin-bottom:10px;
						}
						&.title {
							color: $colour_dark_purple;
							font-size:16px;
							font-weight:600;
						}
					}
					p {
						font-family: $font_pt_sans;
						color: $colour_text_grey;
						font-size:13px;
						a {
							color: green;
						}
					}
				}
			}
		}
	}
}