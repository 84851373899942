html {
  body {
    div#app {
      &.user-manager {
       table {
         span.user_role {
           &::after {
             display:inline-block;
             content: "|";
             padding-left: 5px;
             font-family: $font_pt_sans;
             font-weight:800;
           }
           &:last-child {
             &::after {
               display:none;
             }
           }
         }
       }
      }
    }
  }
}