html {
  body {
    #dropzonefiles {
      margin:0 auto;
      min-height:250px;
      width: 100%;
      border:2px dotted $colour_dark_purple;
      border-radius:10px;
      position:relative;

      .dz-message {
        position: absolute;
        top: 50%;
        width: 100%;
        left: 0;
        margin:0;
        padding:0;
        transform: translateY(-50%);
        font-family: $font_pt_sans;
        font-weight:800;
        font-size:26px;
        color: $colour_dark_purple;
      }
    }
  }
}