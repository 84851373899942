html {
	body {
		.btn {
			text-transform: uppercase;
			.filter-option {
				text-transform: none;
			}
		}
		
		.btn-purple {
			background-color: $colour_dark_purple;
			color:white;
			padding: 8px 30px;
			&:hover {
				background-color: #896cba;
				color:white;
			}
		}
		.btn-grey {
			background-color:$colour_dark_grey;
			color: white;
			padding: 8px 30px;
			&:hover {
				color: white;
				background-color: #dadada;
			}
		}
		.btn-green {
			background-color: $colour_light_green;
			color: white;
			padding:8px 30px;
			border-radius:4px;
			&:hover {
				color: white;
				background-color: rgba(0, 189, 112, 0.7);
			}
		}
		
		div.input-group {
			input[type="text"] {
				border-radius:4px;
			}
		}
		
		
		div.btn-group.bootstrap-select {
			width:100% !important;
			button.dropdown-toggle {
				width:100%;
				border-radius:4px;
				height:40px;
				.filter-option {
					line-height: 28px;
				}
				span.bs-caret {
					position: absolute;
					top: 0;
					right: 0px;
					background-color: $colour_light_green;
					height: 100%;
					width: 38px;
					.caret {
						color: white;
						right:15px;
					}
				}
				&:focus {
					outline: none !important;
				}
			}
			ul.inner {
				li {
					outline: none !important;
					a {
						outline: none !important;
					}
				}
			}
		}
	}
}