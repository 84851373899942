html {
	body {
		div#app {
			&.latest-news {
				h1 {
					color: $colour_dark_purple;
				}
				div.news-item {
					&:nth-child(1) {
						padding-top:40px;
					}
					&:nth-last-child(1) {
						border:none;
						padding-bottom:0px;
					}
					margin-bottom: 65px;
					border-bottom: 2px solid $colour_light_grey;
					padding-bottom: 50px;
					h2 {
						font-family: $font_open_sans;
						font-size:24px;
						color: $colour_dark_purple;
						margin:0;
						padding:0;
						font-weight:600;
						a {
							-webkit-transition: color 250ms linear;
							-moz-transition: color 250ms linear;
							-ms-transition: color 250ms linear;
							-o-transition: color 250ms linear;
							transition: color 250ms linear;
							color: $colour_dark_purple;
							&:hover {
								text-decoration: none;
								color: $colour_light_purple;
							}
							&:active, &:focus {
								text-decoration: none;
							}
						}
					}
					h3 {
						font-family: $font_open_sans;
						font-weight:100;
						font-size:18px;
						color: $colour_dark_grey;
						margin:10px 0 15px 0;
						padding:0;
					}
					h4 {
						font-family: $font_open_sans;
						font-weight:100;
						margin: 10px 0 20px 0;
						font-size:14px;
					}
					div.content {
						p {
							font-family: $font_pt_sans;
							font-weight: 400;;
							font-size:16px;
							line-height: 18px;
							margin: 0 0 15px 0;
						}
					}
				}
			}
			&.add-news {
				div.form-group {
					margin-bottom:40px;
					&:nth-last-child(1) {
						margin-bottom:0px;
					}
				}
			}
		}
	}
}
