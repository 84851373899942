html {
	overflow-y: scroll;
}

$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
@import 'node_modules/font-awesome/scss/font-awesome.scss';

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Fonts
@import "fonts";

//Colours
@import "colours.scss";

//Buttons
@import "custom_buttons.scss";

// Auth template
@import "auth_template.scss";

// Header
@import "header";

// Footer
@import "footer";

//Pagination
@import "pagination";

//Dropzone
@import "dropzone";

//Dashboard
@import "dashboard";

//Latest News
@import "latest-news";

//File Manager
@import "file-manager";

//User Manager
@import "user-manager";

//Swap Shop
@import "swap-shop";

//Messages
@import "messages";

//Directory
@import "directory";

//Fancybox
@import "fancybox";

//General

span.error {
	color: $colour_dark_purple;
	font-weight: bold;
	display:none;
	margin: 0 0 10px 0;
}

.flex-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	
	.col-md-2 a {
		display: block;
		margin-bottom: 30px;
		word-wrap: break-word;
	}
	
}

@media (min-width: 992px) {
	
	.flex-row {
		
		.col-md-2 {
			width: 16.66666667%;
		}
		
	}
	
}