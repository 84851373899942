html {
	body {
		div#app {
			&.directory {
				div.page-heading img {
					float:right;
					max-width:177px;
					height: auto;
				}
				div.branch-directory-container {
					.branch {
						margin-bottom:40px;
					}
					h2 {
						font-family: 'Open Sans', sans-serif;
						font-size: 23px;
						line-height: 20px;
						font-weight: 700;
						color: #752f8a;
						margin: 0;
					}
				}
				
				div.home-directory-container {
					padding-top: 40px;
					img {
						margin: 0 0 70px;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						-ms-border-radius: 50%;
						-o-border-radius: 50%;
						border-radius: 50%;
					}
					h2 {
						font-family: 'Open Sans', sans-serif;
						font-size: 23px;
						line-height: 20px;
						font-weight: 700;
						color: #752f8a;
						margin: 40px 0 0;
					}
					h3 {
						font-family: 'Open Sans', sans-serif;
						font-size: 16px;
						line-height: 20px;
						font-weight: 400;
						color: #a9a9a9;
						margin: 10px 0 30px;
					}
					.btn {
						font-family: 'Open Sans', sans-serif;
					}
				}
				
				div.apex-table {
					div.dataTables_filter {
						label {
							font-weight: 400;
							font-size:18px;
							color: $colour_dark_purple;
							margin-bottom: 35px;
							text-transform: uppercase;
							input {
								border-radius: 3px;
								border: 1px solid $colour_dark_grey;
								height:25px;
								margin: 0 0 0 10px;
								padding: 15px 10px;
								font-size:14px;
								line-height:24px;
								outline: 0;
								color: $colour_text_grey;
								&:focus {
									border-color: $colour_dark_purple;
									outline: 0;
									-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($colour_dark_purple,.6);
									box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($colour_dark_purple,.6);
								}
							}
						}
					}
					table#apexTable {
						font-family: $font_open_sans;
						thead th, thead td {
							padding: 10px 10px;
							color: $colour_dark_purple;
							&:nth-child(2n) {
								color: $colour_light_green;
							}
						}
						span {
							display:block;
							clear:both;
							a {
								color: $colour_dark_purple;
								&:hover {
									text-decoration:none;
									color: $colour_light_green;
								}
							}
						}
						strong {
							font-weight: 600;
							color: $colour_light_green;
						}
					}
				}
				
				div.green {
					background-color: $colour_light_green;
					min-height: 100px;
				}
				div.grey {
					background-color: $colour_light_grey;
					min-height: 100px;
					position:relative;
					padding-top:19px;
					padding-bottom:22px;
				}
				.manager {
					h4 {
						color: $colour_light_green;
					}
				}
				.phone {
					margin-top:30px;
					h4 {
						color: $colour_dark_purple;
					}
				}
				.admin {
					margin-top:30px;
				}
				h4 {
					font-family: 'Open Sans', sans-serif;
					font-weight: 600;
					font-size: 16px;
					margin-bottom: 5px;
					strong {
						font-weight: 600;
					}
				}
				h1 {
					font-family: "Open Sans", sans-serif;
					font-size: 32px;
					font-weight: 100;
					margin: 50px 0 0 0;
					padding: 0;
					text-transform: uppercase;
					color: #752f8a;
					small {
						text-transform: capitalize;
						color: #00bd70;
						display: block;
						margin-top: 10px;
						margin-bottom: 15px;
					}
				}
				ul.staff-list {
					list-style-type: none;
					list-style: none;
					padding:0;
					margin:0;
				}
				div.image-heading {
					position: relative;
					width: 100%;
					max-height: 250px;
					overflow:hidden;
					img {
						width: 100%;
						height: auto;
					}
				}
				div.file-container {
					margin-top:35px;
					width: 100%;
					height: 100%;
					div.secondrow {
						padding-top:50px;
					}
					a.file {
						div.file-preview {
							width: 100%;
							height: auto;
							position: relative;
							margin-bottom:10px;
							-webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							-moz-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							div.overlay {
								position: absolute;
								width:100%;
								height:100%;
								top:0;
								left:0;
								background-color:black;
								opacity:0;
								-webkit-transition: opacity 150ms linear;
								-moz-transition: opacity 150ms linear;
								-ms-transition: opacity 150ms linear;
								-o-transition: opacity 150ms linear;
								transition: opacity 150ms linear;
							}
							img.file-type {
								position: absolute;
								bottom:-10px;
								right:10px;
							}
						}
						span {
							width:100%;
							display:block;
							text-align:center;
							color: $colour_dark_purple;
							padding: 20px 0 0 0;
							font-weight: 800;
							font-family:$font_pt_sans;
							line-height:16px;
							font-size:16px;
							word-wrap: break-word;
							-webkit-transition: background-color 250ms linear, color 250ms linear;
							-moz-transition: background-color 250ms linear, color 250ms linear;
							-ms-transition: background-color 250ms linear, color 250ms linear;
							-o-transition: background-color 250ms linear, color 250ms linear;
							transition: background-color 250ms linear, color 250ms linear;
						}
						&:hover {
							text-decoration:none;
							div.file-permissions, div.favourite {
								opacity: 1;
							}
							
							&.options {
								button {
									opacity:1;
								}
								.file-preview {
									.overlay {
										opacity:0.07;
									}
								}
							}
						}
					}
					div.file-permissions {
						position: absolute;
						top: 0px;
						left: 20px;
						opacity:0;
						-webkit-transition: opacity 150ms linear;
						-moz-transition: opacity 150ms linear;
						-ms-transition: opacity 150ms linear;
						-o-transition: opacity 150ms linear;
						transition: opacity 150ms linear;
						z-index:5;
						&:hover {
							opacity:1;
						}
						a {
							font-size:20px;
							color: $colour_light_green;
						}
						button {
							padding:0;
							margin:0;
							border:none;
							font-size:20px;
							color: $colour_light_green;
							background-color: transparent;
							i {
								padding:5px;
								&:active, &:hover, &:focus {
									outline:0;
									-webkit-box-shadow: none;
									-moz-box-shadow: none;
									box-shadow: none;
									text-decoration:none;
								}
							}
							&:active, &:hover, &:focus {
								outline:0;
								-webkit-box-shadow: none;
								-moz-box-shadow: none;
								box-shadow: none;
								text-decoration:none;
							}
						}
					}
					div.favourite {
						position: absolute;
						top: 0;
						right: 20px;
						opacity: 0;
						-webkit-transition: opacity 150ms linear;
						-moz-transition: opacity 150ms linear;
						-ms-transition: opacity 150ms linear;
						-o-transition: opacity 150ms linear;
						transition: opacity 150ms linear;
						z-index: 5;
						button {
							padding: 0;
							margin: 0;
							border: none;
							font-size: 20px;
							color: $colour_light_green;
							background-color: transparent;
							&:active, &:hover, &:focus {
								outline:0;
								-webkit-box-shadow: none;
								-moz-box-shadow: none;
								box-shadow: none;
								text-decoration:none;
							}
							i {
								padding: 5px;
								&.fa-star {
									position: absolute;
									top: 0;
									right: 0;
									opacity: 0;
									-webkit-transition: opacity 150ms linear;
									-moz-transition: opacity 150ms linear;
									-ms-transition: opacity 150ms linear;
									-o-transition: opacity 150ms linear;
									transition: opacity 150ms linear;
								}
							}
						}
						&.is-favourite {
							button {
								i.fa-star {
									opacity: 1;
								}
							}
						}
					}
					a.folder {
						width: 16.6%;
						height: 150px;
						float:left;
						//position:relative;
						margin-bottom:20px;
						text-decoration:none;
						img {
							width: 120px;
							height: auto;
							-webkit-transition: opacity 250ms linear;
							-moz-transition: opacity 250ms linear;
							-ms-transition: opacity 250ms linear;
							-o-transition: opacity 250ms linear;
							transition: opacity 250ms linear;
							display:block;
							margin: 0 auto;
							
						}
						span.name {
							width:100%;
							display:block;
							text-align:center;
							color: $colour_dark_purple;
							padding: 2px 5px;
							font-weight: 800;
							font-family:$font_pt_sans;
							line-height:16px;
							font-size:16px;
							-webkit-transition: background-color 250ms linear, color 250ms linear;
							-moz-transition: background-color 250ms linear, color 250ms linear;
							-ms-transition: background-color 250ms linear, color 250ms linear;
							-o-transition: background-color 250ms linear, color 250ms linear;
							transition: background-color 250ms linear, color 250ms linear;
							
						}
						&:hover {
							span.name {
								background-color: white;
								color: $colour_light_purple;
								
							}
							img {
								opacity:0.55;
							}
						}
					}
					
				}
			}
		}
	}
}