html {
	body {
		div#app {
			&.messages {
				div.messages {
					div.message {
						width: 100%;
						display: inline-block;
						border: 2px solid #eaeaea;
						margin-bottom: 15px;
						padding: 18px 24px;
						text-decoration: none;
						-webkit-transition: border-color .25s ease;
						-moz-transition: border-color .25s ease;
						-ms-transition: border-color .25s ease;
						-o-transition: border-color .25s ease;
						transition: border-color .25s ease;
					}
					a.message {
						width: 100%;
						display: inline-block;
						border: 2px solid #eaeaea;
						margin-bottom: 15px;
						padding: 14px;
						text-decoration: none;
						-webkit-transition: border-color .25s ease;
						-moz-transition: border-color .25s ease;
						-ms-transition: border-color .25s ease;
						-o-transition: border-color .25s ease;
						transition: border-color .25s ease;
						
						&:hover {
							border-color: #752f8a;
						}
						&.unread {
							border-color: #752f8a;
						}
					}
					.message {
						span {
							display: inline-block;
							&.title {
								font-family: 'Open Sans', sans-serif;
								font-size: 16px;
								font-weight: 700;
								margin: 0 0 10px;
								color: #752f8a;
							}
							&.date {
								font-family: 'Open Sans', sans-serif;
								font-size: 13px;
								opacity: 0.3;
								color: #000000;
							}
						}
						
						div.content, p {
							font-family: 'Open Sans', sans-serif;
							font-size: 15px;
							line-height: 18px;
							margin: 0;
							color: #444444;
							opacity: 0.8;
						}
					}
				}
				.folders {
					background-color: rgba(169, 169, 169, 0.18);
					list-style-type: none;
					margin: 0;
					padding: 26px;
				}
				
				.folders li {
					margin-bottom: 5px;
				}
				
				.folders li.title {
					font-family: 'Open Sans', sans-serif;
					font-size: 24px;
					font-weight: 300;
					margin-bottom: 28px;
				}
				
				.folders li:last-child {
					margin-bottom: 0;
				}
				
				.folders li a {
					font-family: 'Open Sans', sans-serif;
					color: #636b6f;
					text-decoration: none;
					font-size: 16px;
				}
				
				.folders li a.active {
					font-weight: 700;
					color: #752f8a;
				}
				
				.folders li a:hover {
					color: #752f8a;
				}
				.read-message .messages .message p {
					margin-bottom: 15px;
				}
				
				.read-message .messages .message p:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}