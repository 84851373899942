html {
  body {
    div#app {
      &.swap-shop {
        h1 {
          color: $colour_dark_purple;
          text-transform: uppercase;
          font-weight:100;
          margin:0;
          padding:0;
          font-size:34px;
        }
        h2 {
          color: $colour_dark_purple;
          text-transform: capitalize;
          font-size:26px;
          font-weight:100;
          margin:0;
          padding:0;
        }
        div.sorting {
          padding-top:70px;
          padding-bottom:70px;
          input[type='text'] {
            height:40px;
          }
          select {
            height: 40px;
          }
        }
        div.item {
          a {
            opacity:1;
            -webkit-transition: opacity 250ms linear;
            -moz-transition: opacity 250ms linear;
            -ms-transition: opacity 250ms linear;
            -o-transition: opacity 250ms linear;
            transition: opacity 250ms linear;
            span {
              color: $colour_dark_grey;
              display:block;
              width:100%;
              &.name {
                font-weight:800;
                color: $colour_dark_purple;
                padding: 10px 0 0px 0;
              }
            }
            &:hover {
              text-decoration:none;
              opacity:0.8;
            }
          }
          img {
            border-radius:5px;
          }
        }
        div.swap-shop-panel {
          min-height:480px;
          padding-top:70px;
          background-color: $colour_light_grey;
          div.item {
            margin: 30px 0 70px 0;
          }
          .btn {
            text-transform: uppercase;
          }
        }
        div.padding-top {
          padding:40px 0 0 0;
        }
        div.items {
          margin-top:40px;
        }
        .secondrow {
          margin-top: 30px;
        }
      }
      &.swap-shop-item {
        h1 {
          color: $colour_dark_purple;
          text-transform: uppercase;
          font-weight:100;
          margin:70px 0 0 0;
          padding:0;
          font-size:34px;
        }
        h2 {
          color: $colour_dark_purple;
          text-transform: capitalize;
          font-size:26px;
          font-weight:100;
          margin:0;
          padding:0;
        }
        span.name {
          font-weight:600;
          font-size:24px;
          color: $colour_dark_purple;
          margin:0;
          padding:0;
          display:block;
        }
        span.location {
          font-weight:100;
          font-size:20px;
          color: $colour_dark_grey;
          margin: 0 0 20px 0;
          padding:0;
          display:block;
        }
        img.item-image {
          max-width:100%;
          width:100%;
          height: auto;
        }
        div.thumbs {
          div.thumb {
            img {
              width:100%;
              max-width: 100%;
            }
            &.col-md-6 {
               margin-bottom: 15px;
               padding-left: 10px;
               padding-right: 10px;
            }
            &:nth-child(even) {
              float:right;
            }
          }
        }
        div.item-pictures {
          margin-bottom:40px;
          img {
            width:100%;
            height: auto;
          }
        }
        div.content-area, div.contact-area {
          margin-top:40px;
        }
      }
      &.swap-shop-post-item {
        h1 {
          color: $colour_dark_purple;
          text-transform: uppercase;
          font-weight:100;
          margin:0;
          padding:0;
          font-size:34px;
        }
        h2 {
          color: $colour_dark_purple;
          text-transform: capitalize;
          font-size:26px;
          font-weight:100;
          margin:0;
          padding:0 0 20px 0;
        }
        input.title {
          font-size:18px;
          font-weight:600;
        }

        input[type='text'] {
          font-size:15px;
          border-radius:0px;
          //border-color: $colour_dark_grey;
          &:focus {
            border-color: $colour_light_purple;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($colour_light_purple, 0.6);
          }
        }

        button.btn-post {
          margin-top:40px;
        }

        div.image-container {
          margin: 0 0 50px 0;
        }

        div.contact-container {
          div.form-group {
            margin-bottom:25px;
          }
        }

        div.dropzone-container {
          .dropzone.dz-started .dz-message {
            display:block;
          }
          padding-top: 40px;
          padding-bottom: 50px;
        }

        div.thumbs {
          div.thumb {
            img {
              width:100%;
              max-width: 100%;
            }
            &:nth-child(even) {
              float:right;
            }
          }
        }


      }
      &.swap-shop-item, &.swap-shop-post-item {
        position: relative;
        #main-thumb {
          img {
            width: 100%;
            &:not(.original) {
              display: none;
            }
          }
        }
        #thumbs {
          margin: 0 -7px;
          [class^="col-"] {
              padding: 8px;
          }
        }
        div.thumb {
          position: relative;
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: #000000;
            -webkit-transition: opacity .25s ease;
            -moz-transition: opacity .25s ease;
            -ms-transition: opacity .25s ease;
            -o-transition: opacity .25s ease;
            transition: opacity .25s ease;
          }
          &:hover {
            .overlay {
              opacity: 0.4;
            }
            .remove {
              opacity: 1;
            }
          }
        }
        .remove {
          cursor: pointer;
          position: absolute;
          right: 20px;
          bottom: 8px;
          font-size: 28px;
          color: #ffffff;
          opacity: 0;
          -webkit-transition: opacity .25s ease;
          -moz-transition: opacity .25s ease;
          -ms-transition: opacity .25s ease;
          -o-transition: opacity .25s ease;
          transition: opacity .25s ease;
        }
        .dz-preview {
          display: none;
        }
        .dz-default.dz-message {
          display: block;
        }
      }
    }
  }
}