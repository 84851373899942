html {
	body {
		div#fancybox-container {
			padding: 10px 25px;
			div.header {
				margin-top:25px;
				h1 {
					padding:0;
					margin:0;
					font-size:32px;
				}
				h2 {
					padding:10px 0 0 0;
					margin:0;
					font-size:24px;
				}
			}
			div.content {
				margin-top:30px;
				label {
					font-size:18px;
				}
				select.select2-multiple {
					width:100%;
				}
			}
		}
	}
}