div.pagination-container {
	text-align:center;
	margin-top:30px;
	ul.pagination {
		li {
			span, a {
				color: white;
				border: none;
				border-bottom: 2px solid $colour_light_grey;
				border-top: 2px solid $colour_light_grey;
				border-radius:0px;
				background-color: $colour_light_grey;
				color: $colour_dark_purple;
				font-weight: 600;
				line-height:18px;
				font-family: $font_open_sans;
			}
			a {
				&:hover {
					border-left: none;
					border-right: none;
					border-bottom: 2px solid $colour_light_grey;
					border-top: 2px solid $colour_light_grey;
				}
			}
			
			&:nth-child(1) {
				span, a {
					border:none;
					border-top: 2px solid $colour_dark_purple;
					border-bottom: 2px solid $colour_dark_purple;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					background-color: $colour_dark_purple;
					color: white;
				}
			}
			&:nth-last-child(1) {
				span, a {
					border:none;
					border-top: 2px solid $colour_dark_purple;
					border-bottom: 2px solid $colour_dark_purple;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
					background-color: $colour_dark_purple;
					color: white;
				}
			}
			&.active {
				span {
					color: white;
					border: none;
					border-bottom: 2px solid $colour_light_grey;
					border-top: 2px solid $colour_light_grey;
					border-radius:0px;
					background-color: $colour_light_grey;
					color: $colour_dark_purple;
					font-weight: 800;
					line-height:18px;
					font-family: $font_open_sans;
				}
			}
		}
	}
}