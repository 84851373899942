body {
  img {
    max-width:100%;
    height: auto;
  }

  div#auth-container {
    width:100%;
    height:auto;
    position:absolute;
    top:50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    div.panel-auth {
      margin-bottom:0px;
      border-radius:0px;
      margin-top:60px;
      

      div.panel-heading {
        background-color: $colour_dark_purple;
        border-top-right-radius:0px;
        border-top-left-radius:0px;
        color: white;
        font-family: $font_open_sans;
        font-weight: 200;
        padding: 20px 25px;
        font-size:32px;
        text-align:center;
      }

      div.panel-body {
        background-image: url('images/panel/auth-bg.png');
        background-position: 110% 100px;
        background-size: auto auto;
        background-repeat: no-repeat;
        background-color: $colour_light_grey;

        form {
          div.form-group {
            &.has-error {
              input[type="text"], input[type="password"] {
                border-color: $colour_dark_purple;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
              }
            }
          }
          input[type="text"], input[type="password"] {
            margin-left: auto;
            margin-right: auto;

          }
          input[type='text'], input[type='email'] {
            margin-top:50px;
            margin-bottom:25px;
          }
          div.form-buttons {
            margin-bottom:50px;
            margin-top:25px;
          }
          div.form-errors {
            span.help-block {
              color: $colour_dark_purple;
            }
          }
          button.btn-primary {
            background-color: $colour_dark_purple;
            border:1px solid $colour_dark_purple;
            font-size:13px;
            font-family: $font_open_sans;
            font-weight: 400;
            text-transform:uppercase;
            padding: 8px 40px;
          }
          a.btn-link {
            color: $colour_dark_purple;
            font-family: $font_open_sans;
            text-transform: uppercase;
            margin-right:0;
            padding-right:0;
            font-weight:600;
          }
        }
      }

    }

  }

}