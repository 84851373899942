

//Purples
$colour_lighter_purple: #e5cfe2;
$colour_light_purple: #9e6dad;//#c998c2;
$colour_dark_purple: #752f8a;

//Greys
$colour_light_grey: #f4f4f4;
$colour_dark_grey: #a9a9a9;

//Greens
$colour_light_green: #00bd70;

//Text Colours
$colour_text_grey: #767676;