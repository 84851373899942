html {
	body {
		div#app {
			&.file-upload {
				#dropzonefiles {
					min-height:250px;
					width: 100%;
					border:2px dotted $colour_dark_purple;
					.dz-message {
						position: absolute;
						top: 50%;
						width: 100%;
						left: 0;
						transform: translateY(-50%);
						font-family: $font_pt_sans;
						font-weight:800;
						font-size:26px;
						color: $colour_dark_purple;
					}
				}
			}
			&.browse-files {
				.file-upload-dropzone {
					margin-bottom:30px;
				}
				h1 {
					display:inline-block;
					padding:0;
					margin:0;
					font-family: $font_open_sans;
					font-weight:100;
					font-size:36px;
					color: $colour_dark_purple;
				}
				.btn-back {
					margin-left:10px;
				}
				div.file-header {
					margin-top:70px;
					margin-bottom:70px;
					.change-permissions {
						margin-right:20px;
					}
				}

				div.file-container {
					margin-top:50px;
					width: 100%;
					height: 100%;
					div.secondrow {
						padding-top:50px;
					}
					a.file {
						div.file-preview {
							width: 100%;
							height: auto;
							position: relative;
							margin-bottom:10px;
							-webkit-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							-moz-box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
							div.overlay {
								position: absolute;
								width:100%;
								height:100%;
								top:0;
								left:0;
								background-color:black;
								opacity:0;
								-webkit-transition: opacity 150ms linear;
								-moz-transition: opacity 150ms linear;
								-ms-transition: opacity 150ms linear;
								-o-transition: opacity 150ms linear;
								transition: opacity 150ms linear;
							}
							img.file-type {
								position: absolute;
								bottom:-10px;
								right:10px;
							}
						}
						span {
							width:100%;
							display:block;
							text-align:center;
							color: $colour_dark_purple;
							padding: 20px 0 0 0;
							font-weight: 800;
							font-family:$font_pt_sans;
							line-height:16px;
							font-size:16px;
							word-wrap: break-word;
							-webkit-transition: background-color 250ms linear, color 250ms linear;
							-moz-transition: background-color 250ms linear, color 250ms linear;
							-ms-transition: background-color 250ms linear, color 250ms linear;
							-o-transition: background-color 250ms linear, color 250ms linear;
							transition: background-color 250ms linear, color 250ms linear;
						}
						&:hover {
							text-decoration:none;
							div.file-permissions, div.favourite {
								opacity: 1;
							}
							
							&.options {
								button {
									opacity:1;
								}
								.file-preview {
									.overlay {
										opacity:0.07;
									}
								}
							}
						}
					}
					div.file-permissions {
						position: absolute;
						top: 0px;
						left: 20px;
						opacity:0;
						-webkit-transition: opacity 150ms linear;
						-moz-transition: opacity 150ms linear;
						-ms-transition: opacity 150ms linear;
						-o-transition: opacity 150ms linear;
						transition: opacity 150ms linear;
						z-index:5;
						&:hover {
							opacity:1;
						}
						a {
							font-size:20px;
							color: $colour_light_green;
						}
						button {
							padding:0;
							margin:0;
							border:none;
							font-size:20px;
							color: $colour_light_green;
							background-color: transparent;
							i {
								padding:5px;
								&:active, &:hover, &:focus {
									outline:0;
									-webkit-box-shadow: none;
									-moz-box-shadow: none;
									box-shadow: none;
									text-decoration:none;
								}
							}
							&:active, &:hover, &:focus {
								outline:0;
								-webkit-box-shadow: none;
								-moz-box-shadow: none;
								box-shadow: none;
								text-decoration:none;
							}
						}
					}
					div.favourite {
						position: absolute;
						top: 0;
						right: 20px;
						opacity: 0;
						-webkit-transition: opacity 150ms linear;
						-moz-transition: opacity 150ms linear;
						-ms-transition: opacity 150ms linear;
						-o-transition: opacity 150ms linear;
						transition: opacity 150ms linear;
						z-index: 5;
						button {
							padding: 0;
							margin: 0;
							border: none;
							font-size: 20px;
							color: $colour_light_green;
							background-color: transparent;
							&:active, &:hover, &:focus {
								outline:0;
								-webkit-box-shadow: none;
								-moz-box-shadow: none;
								box-shadow: none;
								text-decoration:none;
							}
							i {
								padding: 5px;
								&.fa-star {
									position: absolute;
									top: 0;
									right: 0;
									opacity: 0;
									-webkit-transition: opacity 150ms linear;
									-moz-transition: opacity 150ms linear;
									-ms-transition: opacity 150ms linear;
									-o-transition: opacity 150ms linear;
									transition: opacity 150ms linear;
								}
							}
						}
						&.is-favourite {
							button {
								i.fa-star {
									opacity: 1;
								}
							}
						}
					}
					a.folder {
						width: 20%;
						height: 150px;
						float:left;
						//position:relative;
						margin-bottom:20px;
						text-decoration:none;
						img {
							width: 120px;
							height: auto;
							-webkit-transition: opacity 250ms linear;
							-moz-transition: opacity 250ms linear;
							-ms-transition: opacity 250ms linear;
							-o-transition: opacity 250ms linear;
							transition: opacity 250ms linear;
							display:block;
							margin: 0 auto;
							
						}
						span.name {
							width:100%;
							display:block;
							text-align:center;
							color: $colour_dark_purple;
							padding: 2px 5px;
							font-weight: 800;
							font-family:$font_pt_sans;
							line-height:16px;
							font-size:16px;
							-webkit-transition: background-color 250ms linear, color 250ms linear;
							-moz-transition: background-color 250ms linear, color 250ms linear;
							-ms-transition: background-color 250ms linear, color 250ms linear;
							-o-transition: background-color 250ms linear, color 250ms linear;
							transition: background-color 250ms linear, color 250ms linear;
							
						}
						&:hover {
							span.name {
								background-color: white;
								color: $colour_light_purple;
								
							}
							img {
								opacity:0.55;
							}
						}
					}
					
				}
				
				
			}
			
			div.folder-search-container {
				margin-top:70px;
				margin-bottom:70px;
				button {
					height: 36px;
					line-height: 20px;
				}
			}
			div.button-container {
				clear:both;
				margin-top:70px;
				margin-bottom:70px;
				
				.btn-back {
					margin-left:0;
					margin-right: 20px;
				}
			}
			h1.folder-index {
				margin-top:0px;
				margin-bottom:70px;
			}
		}
	}
}